<template>
  <div>
    <div class="footer" v-if="!dashboard && !partial">
      <div class="footer_main container">
        <div class="footer_left">
          <div class="footer_logo"></div>
          <p class="footer_logo_subtitle">
            Empréstimo para micro e pequenas empresas financiados por pessoas
            P2P Lending
          </p>
          <div class="block lg:hidden">
            <label for="emailNewslatter" class="footer_right_title"
              >Receba novidades e informações</label
            >
            <div class="footer_right_form">
              <span class="error">{{ errors.first("email") }}</span>
              <input
                class="footer_right_input"
                type="text"
                placeholder="Seu melhor e-mail"
                id="emailNewslatter"
                v-model="email"
                name="email"
                v-validate="'required|email'"
              />
              <button class="footer_right_btn" @click="signNewsletter">
                <i class="fas fa-spinner fa-spin" v-if="loading"></i>
                <span v-else>Quero receber</span>
              </button>
            </div>
          </div>
          <p class="footer_text mt-2">Suporte via Whatsapp</p>
          <p class="footer_phone">
            <a
              href="https://api.whatsapp.com/send/?phone=5511991121535&text&app_absent=0"
              target="_blank"
              >(11) 99112-1535</a
            >
          </p>
          <p class="footer_text mt-2">Atendimento para empréstimos</p>
          <p class="footer_phone">
            <a
              href="https://api.whatsapp.com/send/?phone=5511971981762&text&app_absent=0"
              target="_blank"
              >(11) 97198-1762</a
            >
          </p>
          <p class="footer_text mt-8 lg:mt-16">quero@tutudigital.com.br</p>
          <p class="footer_text">Av. Paulista, 1374</p>
          <p class="footer_text">Andar 11, São Paulo, SP</p>
        </div>

        <div class="footer_right">
          <div class="hidden lg:block">
            <p class="footer_right_title">Receba novidades e informações</p>
            <div class="footer_right_form">
              <span class="error">{{ errors.first("email") }}</span>
              <input
                class="footer_right_input"
                type="text"
                placeholder="Seu melhor e-mail"
                v-model="email"
                name="email"
                v-validate="'required|email'"
              />
              <button class="footer_right_btn" @click="signNewsletter">
                <i class="fas fa-spinner fa-spin" v-if="loading"></i>
                <span v-else>Quero receber</span>
              </button>
            </div>
          </div>
          <p class="footer_right_text">
            tutudigital.com.br pertence à Tutu Digital Tecnologia Ltda (“TuTu
            Digital”). A TuTu Digital não é uma instituição financeira e não
            realiza operações de crédito diretamente, atua como um
            correspondente bancário de uma instituição financeira parceira nos
            termos do artigo 2º, da Resolução CMN nº 3.954, de 24 de fevereiro
            de 2011. <br />

            Informações adicionais: Prazo de pagamento: De 2 a 24 meses. Custo
            Efetivo Total: CET mínimo de 2,00% e máximo de 3,60% ao mês (26,82%
            a 52,87% ao ano). Exemplo ilustrativo: Suponha empréstimo de R$
            100.000,00, para pagar em 2 anos (24 meses) e CET de 2,2% ao mês
            (29,90% ao ano). Total 24 parcelas de R$ 5.568,97. Valor total a
            pagar igual a R$ 133.655,16.<br />

            A Tutu Digital Tecnologia Ltda não realiza operações de
            financiamento e/ou investimento diretamente, tampouco realiza
            quaisquer outras operações privativas de instituições financeiras.
            Todos os financiamentos são realizados através de uma instituição
            financeira parceira, devidamente autorizada pelo Banco Central do
            Brasil a realizar esse tipo de atividade.
          </p>

          <div class="flex flex-col lg:flex-row lg:mt-4">
            <img
              class="mb-4 lg:mb-0 lg:mr-6 h-10"
              src="../../assets/images/landing/lift.webp"
              alt="Lift"
            />
            <img
              class="h-10 mb-8 lg:mb-0"
              src="../../assets/images/landing/banco-central.webp"
              alt="Banco Central"
            />
          </div>
          <!--
          <div class="footer_right mt-5 mb-0">
            <p class="footer_text">
              <router-link to="/termos-condicoes" class="link">Termos e Condições para Tomadores e Investidores
              </router-link>
            </p>
          </div>
          -->
        </div>
      </div>
    </div>
    <div class="footer_copy" v-if="!dashboard">
      <p class="footer_copy_text container">
        Copyright © TuTu Digital. Todos os Direitos Reservados. Tutu Digital
        Tecnologia Ltda – CNPJ 28.304.222/0001-17, Avenida Paulista, 1374 -
        Andar 11, São Paulo, SP. CEP 01310-100. Esse site está em fase
        pré-operacional e sendo aprimorado pela equipe da TuTu Digital. Até que
        esse aprimoramento seja concluído, todas as operações serão realizadas a
        partir do contato individualizada da Tutu Digital com cada cliente.
      </p>
    </div>
    <div
      v-if="dashboard"
      class="container w-full flex items-center justify-center py-6"
    >
      <img
        class="w-24 h-8 lg:w-48 lg:h-10"
        src="../../assets/images/logo_gray.svg"
        alt="logo"
      />
    </div>
  </div>
</template>

<script>
import NewsletterContact from "@/services/Commons/NewsletterContact";

export default {
  name: "footer-main",
  props: ["dashboard", "partial"],
  data() {
    return {
      email: "",
      loading: false,
    };
  },
  methods: {
    async signNewsletter() {
      if (!this.loading) {
        if (await this.$validator.validateAll()) {
          this.loading = true;
          try {
            const grecaptcha = await this.$recaptcha("login");
            await NewsletterContact.newsletter({
              email: this.email,
              grecaptcha,
            });
            this.$store.dispatch("notification", {
              type: "success",
              message: "Registrado na nossa Newsletter!",
            });
          } catch (err) {
            this.$store.dispatch("notification", {
              type: "error",
              message: "Algo deu errado. Tente novamente.",
            });
          } finally {
            this.loading = false;
          }
        }
      }
    },
  },
};
</script>

<style lang="sass" scoped>
@tailwind utilities
.footer
  @apply w-full flex flex-col items-center
  background: #F6F6F6
  @screen lg
    @apply w-full flex flex-col items-center pt-8 pb-6
    background: #F6F6F6

.footer_main
  @apply flex flex-col items-center
  @screen lg
    @apply flex flex-row flex-wrap justify-center

.footer_left
  @apply w-full flex flex-col items-center
  @screen lg
    @apply w-1/4 items-start

.footer_right
  @apply w-full flex flex-col items-center
  @screen lg
    @apply w-3/4 items-end

.footer_logo
  @apply bg-no-repeat bg-contain bg-center w-48 h-10 mt-8
  background-image: url("../../assets/images/logo.svg")
  @screen lg
    @apply bg-no-repeat bg-contain w-48 h-10 mt-0
    background-image: url("../../assets/images/logo.svg")

.footer_logo_subtitle
  @apply w-full text-xs text-center text-gray-600 mt-5 opacity-50
  @screen lg
    @apply w-56 text-xs text-left text-gray-600 mt-5 opacity-50

.footer_phone
  @apply text-2xl text-secondary

.footer_text
  @apply text-xss text-center leading-loose text-gray-700
  @screen lg
    @apply text-xss leading-loose text-gray-600

.footer_right_title
  @apply text-base text-center text-primary mt-6
  @screen lg
    @apply text-2xl text-right text-primary mt-0

.footer_right_form
  @apply relative w-full h-12 mt-5 text-xs
  @screen lg
    @apply relative w-96 h-12 mt-5 text-xs

.footer_right_input
  @apply relative w-4/5 h-14 pl-2 border border-gray-400 text-xs rounded-full z-0
  &:hover, &:focus
    @apply outline-none
  @screen lg
    @apply relative w-4/5 h-14 pl-4 border border-gray-400 rounded-full z-0
    &:hover, &:focus
      @apply outline-none

.error
  @apply absolute text-red-600 text-xss mt-8 ml-2
  @screen lg
    @apply mt-10 ml-4

.footer_right_btn
  @apply absolute right-0 w-24 h-14 bg-secondary text-white text-xs rounded-full z-30
  &:hover, &:focus
    @apply outline-none
  @screen lg
    @apply absolute right-0 w-40 h-14 bg-secondary text-white rounded-full z-30
    &:hover, &:focus
      @apply outline-none

.footer_right_text
  @apply w-full text-xs text-gray-600 text-center leading-loose mt-10 mb-8
  @screen lg
    @apply w-11/12 text-xs text-gray-600 text-right leading-loose mt-8 mb-0

.footer_copy
  @apply w-full bg-primary flex justify-center p-4 pb-12

.footer_copy_text
  @apply text-center text-xs text-white leading-relaxed
</style>
